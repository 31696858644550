import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../components/theme/Layout'
import Footer from '../../components/theme/Footer'
import Header from '../../components/theme/Header'
import BlueHeader from '../../components/common/BlueHeader'
import RenderBlock from '../../components/blocks/RenderBlock'
import Container from '../../components/common/Container'
import FAQListing from '../../components/pages/FAQListing'
import Offers from '../../components/common/Offers'


const FAQ = ({ data }) => (
  <Layout
    meta={data.defaultPage.meta}
    urls={data.defaultPage.urls}
    locale='fr'
  >
    <Header
      urls={data.defaultPage.urls}
      locale='fr'
    />

    <BlueHeader title={data.defaultPage.title} />

    <main>
      <Container width={830}>
        <FAQListing
          locale='fr'
          index={data.siteSearchIndex.index}
          original={data.allFaqPage.edges}
        />
      </Container>
    </main>

    <RenderBlock blocks={data.defaultPage.blocks} />

    <Footer
      extraRules='*Les produits de papier Royale sont fabriqués par Les Produits de consommation Irving Limitée, une société affiliée à J.D. Irving, Limited, elle-même réputée pour sa gestion forestière responsable et ses contributions à la recherche sur les écosystèmes, à la conservation des habitats et au reboisement.'
    />
    <Offers />
  </Layout>
)

export const query = graphql`
query {
  defaultPage(guid: {eq:"c1ba4863-8f29-4802-bd3d-0f74c7244288"}, locale: {eq:"fr"}) {
    title
    title_html
    subtitle
    description
    blocks {
      __typename
      ...productCategoriesBlockFragment
    }
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
      title
    }
  }
  allFaqPage(filter:{locale:{eq:"fr"}}) {
    edges {
      node {
        guid
        question
        answer
        category
      }
    }
  }
  siteSearchIndex {
    index
  }
}
`

export default FAQ
